<template>
   <div class="tooltip" :id="'tooltip-id-' + sorting.sortingTabs[0].sortingName">
     <div class="tooltip__block">
      <div v-for="sort, idx in sorting.sortingTabs" :key="`${sort.sortingName}-${idx}`" class="tooltip__block">
         <Input :inputClass="'tooltip__input js-tooltip-input'" :name="sort.sortingName" :type="'radio'" :label="true"
            :id="`${sort.sortingName}-${idx}-asc`">
         <label :data-group="sort.sortingName" :for="`${sort.sortingName}-${idx}-asc`"
            @click="onSortingBy(sort.sortingAsc, $event)">
            {{ sort.sortingAscTitle }}
         </label>
         </Input>
         <Input :inputClass="'tooltip__input js-tooltip-input'" :name="sort.sortingName" :type="'radio'" :label="true"
            :id="`${sort.sortingName}-${idx}-desc`">
         <label :data-group="sort.sortingName" :for="`${sort.sortingName}-${idx}-desc`"
            @click="onSortingBy(sort.sortingDesc, $event)">
            {{ sort.sortingDescTitle}}
         </label>
         </Input>
      </div>
   </div>
     </div>
</template>


<script>
   import Input from '@/components/UI/Input.vue'
   export default {
      props: {
         sorting: {
            type: Object,
            require: false
         }
      },
      components: {
         Input,
      },
      methods: {
         onSortingBy(query, event) {
            let label = event.target
            let queryGroup = label.dataset.group

            this.$emit('addOrdering', {
               queryGroup: queryGroup,
               query: query
            })
         }
      }
   }
</script>

<style lang="scss">
  
</style>