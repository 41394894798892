import axs from "@/service/AXS";

export default {
    state: {
        user: null,
        queryOption: {
            searchTxt: '',
            ordering: {},
            filters: {}
        }
    },
    getters: {
        getUser(state) {
            return state.user
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
    },
    actions: {
        uploadUser({ commit, state }, userId) {
            return axs.get(`/security/users/${userId}`)
                .then(response => commit('setUser', response.data))
        },
        changeUser({ commit, state }, { action, userId }) {
            return axs.post(`/security/users/${userId}/${action}`, {})
                .then(response => this.dispatch('uploadUser', userId))
                .catch(error => console.log(error.response.data.detail))
        },
    },
}