<template>
  <div class="loader-block" v-if="loading">
    {{isLoading}}
    <Loader />
  </div>
  <div class="container" v-else>
    <Title :title="`Управление организацией - ${getCompanyName}`" />

    <div class="search-block">
      <Search :withFilter="true" @search='onSearch' />
      <span class="sort-block" @click="showPopup = true">
        <img src="@/assets/images/icons/filter.svg" alt="filter">
      </span>
    </div>

    <UserTable :users="getUsers" />

    <transition name="fade">
      <Modal v-if="showPopup" @close="closePopup">
        <template slot="modal-content">
          <div class="popup__title">
            Фильтрация по должности
          </div>
          <ul class="tooltip__block">
            <li v-for="position, idx in getPositions" :key="idx">
              <input type="checkbox" :value="position.name" class="tooltip__input" :id="'position-' + idx"
                v-model="currentPosition">
              <label :for="'position-' + idx">
                {{position.name}}
              </label>
            </li>
          </ul>
          <div class="popup__controls">
            <Button @click="toFilter">
              Применить
            </Button>
            <Button :class="'btn--grey'" @click="clearFilters">
              Сбросить
            </Button>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>


<script>
  import Loader from '@/components/Loader'
  import Title from '@/components/UI/Title'
  import Search from '@/components/UI/Search'
  import UserTable from '@/components/Tables/User/Table'
  import Modal from '@/components/UI/Modal'
  import Button from '@/components/UI/Button'

  export default {
    components: {
      Loader,
      Title,
      Search,
      UserTable,
      Modal,
      Button
    },
    data() {
      return {
        loading: true,
        showPopup: false,
        currentPosition: [],
        queryOption: {
          searchTxt: '',
          ordering: {},
          filters: {},
        }
      }
    },
    created() {
      this.$store.dispatch('uploadUsers', this.queryOption)
      this.$store.dispatch('uploadUsersPosition')
    },
    computed: {
      isLoading() {
        this.getUsers
      },
      getCompanyName() {
        let profile = this.$store.getters.getProfile
        return profile.supervisor.name
      },
      getUsers() {
        let userList = this.$store.getters.getUsers
        if (userList) this.loading = false
        return userList
      },
      getPositions() {
        let usersPosition = this.$store.getters.getUsersPosition
        return usersPosition
      }
    },
    methods: {
      onSearch(searchTxt) {
        this.queryOption.searchTxt = searchTxt
        this.$store.dispatch('uploadUsers', this.queryOption)
      },
      toFilter() {
        this.queryOption.position = this.currentPosition.map(elem => `&position=${elem}`).join('')
        this.$store.dispatch('uploadUsers', this.queryOption)
        this.closePopup()
      },
      clearFilters() {
        this.currentPosition = []
        this.queryOption.position = null
        this.$store.dispatch('uploadUsers', this.queryOption)
        this.closePopup()
      },
      closePopup() {
        this.showPopup = false
      },
    }

  };
</script>

<style lang="scss" scoped>
  .search-block {
    margin-top: 3.2rem;
  }

  .user-list {
    margin-top: 1.6rem;
  }
</style>