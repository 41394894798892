<template>
    <div>
        <input 
            :type="type"
            :required="required"
            :name="name"
            :id="id"
            :class="inputClass"
            :placeholder="placeholder"
            :checked="checked"
        >
        <slot v-if="label"/>
    </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'text'
      },
      inputClass: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      label: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        default: ''
      },
      checked: {
        type: String,
        default: ''
      },
       placeholder: {
        type: String,
        default: ''
      }
    }
  }

</script>

<style lang="scss">

</style>
