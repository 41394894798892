<template>
  <div class="loader-block" v-if="loading">
    {{isLoading}}
    <Loader />
  </div>
  <div class="container" v-else>
    <Title :title="`История активности - ${getUser.user.supervisor.name} - ${getUser.user.fio}`"
      :backLink="true" :backLinkUrl="{ name: 'UsersList' }" v-if="getUser.user.isEmployee"/>
    <Title :title="`История активности - ${getUser.user.fio}`"
      :backLink="true" :backLinkUrl="{ name: 'UsersList' }" v-else/>

    <div class="user__subheader">
      <div class="user__info">
        <div class="user__info-name">
          {{getUser.user.fio}}
        </div>
        <div class="user__info-position">
          {{getUser.user.position}}
        </div>
      </div>
      <div class="user__controls">
        <Button :class="'btn--white'" @click="changeUser('full_logout')">
          Выход со всех устройств
        </Button>
        <Button :class="'btn--white'" @click="changeUser('reset_password')">
          Сброс пароля
        </Button>
          <template v-if="getUser.user.isEmployee">
             <Button @click="changeUser('unblock')" v-if="getUser.user.isBlocked">
              Разблокировать
            </Button>
            <Button :class="'btn--dark'" @click="changeUser('block')" v-else>
              Заблокировать
            </Button>
          </template>
      </div>
    </div>

    <DeviceTable :devices="getUser.devices" />
  </div>
</template>


<script>
  import Loader from '@/components/Loader'
  import Title from '@/components/UI/Title'
  import DeviceTable from '@/components/Tables/Device/Table'
  import Button from '@/components/UI/Button'

  export default {
    components: {
      Loader,
      Title,
      DeviceTable,
      Button,
    },
    data() {
      return {
        loading: true,
        isSend: null,
        userId: this.$route.params.id,
      }
    },
    created() {
      this.$store.dispatch('uploadUser', this.userId)
      this.$store.dispatch('uploadDevices', this.userId)
    },
    computed: {
      isLoading() {
        this.getUser
      },
      getUser() {
        let user = this.$store.getters.getUser
        let devices = this.$store.getters.getDevices
        if (user && devices) this.loading = false
        return {user, devices}
      },
    },
    methods: {
      async changeUser(action) {
        this.isSend = true
        await this.$store.dispatch('changeUser', {
          action,
          userId: this.userId
        })
        this.isSend = false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .search-block {
    margin-top: 3.2rem;
  }

  .device-list {
    margin-top: 1.6rem;
  }
</style>