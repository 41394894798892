<template>
  <div class="wrapper" ref="wrapper" @click="onShowSorting">
    <Header @showNotice="showNotice = true"/>
    <main class="main">
      <router-view/>
    </main>
    <transition name="fade">
      <Modal v-if="getError" @close="closePopup">
        <template slot="modal-content">
          <div class="popup__title">
            {{ getError }}
          </div>
        </template>
      </Modal>

      <Modal v-if="showNotice" @close="showNotice = false">
        <template slot="modal-content">
          <div class="popup__title">
            Уведомления
          </div>
          <div class="popup__grid" v-if="getNotification.length">
            <NoticeCard v-for="notice in getNotification" :key="notice.id" :notice="notice"
                        @click.native="changeNotice(notice)"/>
          </div>
          <div class="su-empty su-about-empty" v-else>
            <EmptyBlock/>
          </div>
          <div class="notice-clear" v-if="getNotification.length">
            <button class="btn" @click="clearNotice">
              Очистить
            </button>
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script>
import API from '@/service/API'
import Header from '@/components/Header.vue'
import Modal from '@/components/UI/Modal'

import NoticeCard from '@/components/Cards/Notice.vue'
import EmptyBlock from '@/components/Empty'

import {getToken, messaging, onMessage} from '@/service/firebase'

export default {
  components: {
    Header,
    Modal,
    NoticeCard,
    EmptyBlock,
  },
  data() {
    return {
      showPopup: false,
      showNotice: false,
    }
  },
  mounted() {
    this.$store.dispatch('isNotReadNotification')
    this.startMessaging()
  },
  computed: {
    getError() {
      return this.$store.getters.getErrorText
    },
    getNotification() {
      return this.$store.getters.getNotification
    },
  },
  methods: {
    onShowSorting(event) {
      const wrapper = this.$refs.wrapper
      const elemOnClick = event.target;
      let eventElemId
      if (wrapper.classList.contains('tooltip-is-open')) {
        if (elemOnClick.classList.contains('opened')) return

        API.toCloseSorting(wrapper)
        if (elemOnClick.classList.contains('js-tooltip-icon')) {
          eventElemId = elemOnClick.dataset.id
          if (this.showSortingId != eventElemId) {
            this.showSortingId = eventElemId
            API.toShowSorting(wrapper, eventElemId)
            return
          }
        }
        this.showSortingId = null
      } else {
        if (!elemOnClick.classList.contains('js-tooltip-icon')) return
        eventElemId = elemOnClick.dataset.id
        this.showSortingId = eventElemId
        API.toShowSorting(wrapper, eventElemId)
      }
    },
    closePopup() {
      this.$store.dispatch('updateError', '')
      this.showPopup = false
    },
    changeNotice(notice) {
      this.showNotice = false
      this.$store.dispatch('changeNoticeSeen', notice.id)
    },
    clearNotice() {
      this.$store.dispatch('deleteNoticeSeen')
    },
    startMessaging() {
      Notification.requestPermission().then(
          (permission) => {
            if (permission === 'granted') {
              getToken(
                  messaging,
                  {vapidKey: this.$store.state.VAPID_KEY}
              ).then((currentToken) => {
                    if (currentToken) {
                      onMessage(messaging, (payload) => {
                        API.incommingMsg();

                        this.$store.dispatch('isNotReadNotification')
                        const data = {
                          ...payload.notification,
                          ...payload.data
                        };
                        const notificationTitle = data.title;
                        const notificationOptions = {
                          body: data.body,
                          icon: data.icon,
                          image: data.image,
                          requireInteraction: true,
                          click_action: data.click_action,
                          data
                        };
                        new Notification(notificationTitle, notificationOptions);
                      })
                    }
                  }
              ).catch(e => alert(e))
            }
          }
      )
    },
  }
}
</script>

<style>

</style>