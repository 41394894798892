import axs from "@/service/AXS";
export default {
	state: {
		notification: [],
		notReadNotification: false,
	},
	getters: {
		getNotification(state) {
			return state.notification
		},
		getNotReadNotification(state) {
			return state.notReadNotification
		}
	},
	mutations: {
		setNotification(state, notification) {
			state.notification = notification
		},
		setNotReadNotification(state, bool) {
			state.notReadNotification = bool
		}
	},
	actions: {
		uploadNotification({
			commit,
			state
		}) {
			this.dispatch('isNotReadNotification')
			return axs.get(`/security/notifications`)
				.then(response => commit('setNotification', response.data))
				.catch(error => console.log(error))
		},
		isNotReadNotification({
			commit,
			state
		}) {
			return axs.get(`/security/notifications/exists/unread`)
				.then(response => commit('setNotReadNotification', response.data.exists))
				.catch(error => console.log(error))
		},
		changeNoticeSeen({
			commit,
			state
		}, noticeId) {
			return axs.put(`/security/notifications/`, {
					"values": [noticeId]
				})
				.then(response => this.dispatch('uploadNotification'))
				.catch(error => console.log(error))
		},

		deleteNoticeSeen({
			commit,
			state
		}) {
			let allNotification = state.notification
			let noticeForDelete = allNotification.filter(notice => notice.seen).map(notice => notice.id)

			if (!noticeForDelete.length) return

			return axs.delete(`/security/notifications`, {
					data: {
						"values": noticeForDelete
					}
				})
				.then(response => this.dispatch('uploadNotification'))
				.catch(error => console.log(error))
		}
	},
}