<template>
    <div class="table__tr">
        <div class="table__td">
            <router-link :to="{name: 'UserSingle', params: {id: user.id}}" class="text text--green">
                {{user.fio}}
            </router-link>
            <div class="text text--sm text--grey">
                {{user.position}}
            </div>
        </div>
        <div class="table__td">
            <div class="text">
                Дата регистрации
            </div>
            <div class="text text--sm text--grey">
                {{ Math.floor(new Date(+user.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY HH:mm") }}
            </div>
        </div>
        <div class="table__td">
            <router-link :to="{name: 'UserSingle', params: {id: user.id}}" class="table__link">
                Просмотр
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            user: {
                type: Object,
                require: true
            },
        },
    }
</script>

<style lang="scss" scoped>
.table__td {
    max-width: calc(100% / 3);
}
</style>