<template>
  <form class="form form--login" @submit.prevent="onSubmit">
    <div class="form__inner">
      <div class="form__title">
        <h2>
          Изменение пароля
        </h2>
        <p>
          Придумайте новый пароль
        </p>
      </div>
      <div class="form__fields">
        <div class="form__item" :class="{ 'form__item--error': $v.user.password.$error }">
          <input class="form__input" id="password" v-model.lazy="user.password"
            :class="{'form__input--empty': !user.password}" type="password" @change="$v.user.password.$touch()"
            placeholder="Новый пароль">
          <label for="password" class="form__label"><span>Новый пароль</span></label>
          <p class="form__item-error" v-if="!$v.user.password.required">
            Обязательное поле
          </p>
            <p class="form__item-error" v-if="!$v.user.password.minLength || !$v.user.password.maxLength || !$v.user.password.alpha">
              Код должен быть длиной от {{$v.user.password.$params.minLength.min}} до {{$v.user.password.$params.maxLength.max}} символов 
              и состоять из латинских букв любого регистра или цифр
            </p>
        </div>

        <div class="form__item" :class="{ 'form__item--error': $v.user.confirmPassword.$error }">
          <input class="form__input" id="confirmPassword" v-model.lazy="user.confirmPassword"
            :class="{'form__input--empty': !user.confirmPassword}" type="password" @change="$v.user.confirmPassword.$touch()"
            placeholder="Повторить пароль">
          <label for="confirmPassword" class="form__label"><span>Повторить пароль</span></label>
          <p class="form__item-error" v-if="!$v.user.confirmPassword.required">
            Обязательное поле
          </p>
           <p class="form__item-error" v-if="!$v.user.confirmPassword.sameAs">
            Пароли не совпадают
          </p>
        </div>
      </div>


      <div class="form__controls" :class="{'form-send': isSend}">
        <Button>
          Сменить пароль
        </Button>
      </div>
    </div>

  </form>
</template>

<script>
  import {
    required,
    sameAs,
    minLength,
    maxLength,
  } from 'vuelidate/lib/validators'
  import Button from '@/components/UI/Button'

  export default {
    components: {
      Button
    },
    data() {
      return {
        isSend: null,
        user: {
          password: '',
          confirmPassword: ''
        }
      }
    },
    validations: {
      user: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(14),
          alpha: val => /^[a-zA-Z0-9]*$/i.test(val),
        },
        confirmPassword: {
          required, 
          sameAs: sameAs('password')
        }
      }
    },
    methods: {
      async onSubmit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const user = {
            password: this.user.password,
          }

          this.isSend = true
          await this.$store.dispatch('changePassword', user)
          this.isSend = false

          if (this.$store.state.errorText) {
            this.user.password = ''
            this.user.confirmPassword = ''
            this.$v.$reset()
          } else {
            this.$router.push({name: 'UsersList'})
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>