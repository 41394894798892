import axs from "@/service/AXS";
import router from '@/router';
import { getTokens } from '@/service/_token';

export default {
    state: {
        profile: null,
    },
    getters: {
        getProfile(state) {
            return state.profile
        },
    },
    mutations: {
        setProfile(state, profile) {
            state.profile = profile
        },
    },
    actions: {
        async uploadProfile({ commit, state }) {
            if (!getTokens('access_token')) return commit('setProfile', null)

            return await axs.get('/security/profile')
                .then(response => {
                    let profile = response.data
                    
                    if(profile.needPasswordChange && router.currentRoute.name !== 'ChangePassword') {
                        router.push({name: 'ChangePassword'})
                    } 

                    if (router.currentRoute.name === 'Login' ) {
                        router.push({name: 'UsersList'})
                    }

                    commit('setProfile', profile)
                })
                .catch(error => console.log(error))
        },
        async changePassword({ commit, state }, user) {
            return await axs.post('/security/profile/set_password', user)
                .then(response => console.log(response))
                .catch(error => console.log(error))
        }
    },
}