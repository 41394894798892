const UserList = [{
    title: 'Пользователь',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [{
        sortingName: 'fio',
        sortingAsc: 'fio',
        sortingDesc: '-fio',
        sortingAscTitle: 'Сортировка “От А до Я”',
        sortingDescTitle: 'Сортировка “От Я до А”'
      }, ],
    }
  },
  {
    title: 'Дата и время <br> регистрации в сервисе',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [{
        sortingName: 'created_at',
        sortingAsc: 'created_at',
        sortingDesc: '-created_at',
        sortingAscTitle: 'Сначала старые ',
        sortingDescTitle: 'Сначала новые'
      }],
    }
  },
  {
    title: 'История активности',
    isSorting: false,
    isFilter: false,
    sorting: {},
    filters: {}
  },
 
]

export default UserList