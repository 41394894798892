<template>
  <div class="table__th">
    <span class="table__th-icon" v-if="tHeader.isSorting">
      <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
        :data-id="'tooltip-id-' + tHeader.sorting.sortingTabs[0].sortingName">
    </span>
    <span class="table__th-icon" v-if="tHeader.isFilter">
      <img src="@/assets/images/icons/filter.svg" alt="filter" class="js-tooltip-icon"
        :data-id="'tooltip-id-' + tHeader.filters.filtersName">
    </span>
    <div v-html="tHeader.title"></div>
    <Sorting :sorting="tHeader.sorting" v-if="tHeader.isSorting" />
    <Filtration :filters="tHeader.filters" v-if="tHeader.isFilter" />
  </div>
</template>


<script>
  import Sorting from '@/components/UI/Sorting'
  import Filtration from '@/components/UI/Filtration'

  export default {
    components: {
      Sorting,
      Filtration
    },
    props: {
      tHeader: {
        type: Object,
        require: true
      },
    },
  }
</script>

<style lang="scss" scoped>
.table__th {
    max-width: calc(100% / 4);
}
</style>
