<template>
   <div class="tooltip" :id="'tooltip-id-' + filters.filtersName">
         <Input v-for="filter, idx in filters.filtersTabs" :key="idx" :inputClass="'tooltip__input js-tooltip-input'"
            :name="filters.filtersName" :type="'radio'" :label="true" :id="filters.filtersName + '_' + idx">
         <label :data-group="filters.filtersName" :for="filters.filtersName + '_' + idx"
            @click="onFiltrationBy(filter.filterTabRequest, $event)">
            {{ filter.filterTabTitle }}
         </label>
         </Input>
   </div>
</template>


<script>
   import Input from '@/components/UI/Input.vue'

   export default {
      props: {
         filters: {
            type: Object,
            require: false
         }
      },
      components: {
         Input,
      },
      data() {
         return {}
      },
      methods: {
         onFiltrationBy(query) {
            let label = event.target
            let queryGroup = label.dataset.group
            this.$emit('addFiltration', {
               queryGroup: queryGroup,
               query: query
            })
         },
      }
   }
</script>

<style lang="scss">
   .sorting {
      .input-controlls+.input-controlls {
         margin-top: 1.6rem;
      }
   }

</style>

 