import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Moment from 'vue-moment'

import App from './App.vue'
import router from './router'
import store from './store'

import './assets/scss/style.scss'

Vue.use(Vuelidate)
Vue.use(Moment);

Vue.config.productionTip = false

let app = new Vue({router, store, render: h => h(App)})
store.dispatch('uploadProfile').then(() => {
    app.$mount('#app')
});

