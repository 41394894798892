<template>
  <div id="app" class="app">
    <AdminLayout v-if="isAuth" />
    <GuestLayout v-if="!isAuth" />
  </div>
</template>

<script>
  import AdminLayout from "@/views/layouts/AdminLayout";
  import GuestLayout from "@/views/layouts/GuestLayout";

  export default {
    components: {
      AdminLayout,
      GuestLayout
    },
    computed: {
      isAuth() {
        return this.$store.getters.getProfile
      }
    },

  };
</script>