<template>
  <button class="btn" :class="btnClass" v-on="$listeners"> 
    <Miniloader/>
    <span class="btn-text">
      <slot />
    </span>
  </button>
</template>

<script>
import Miniloader from '@/components/UI/MiniLoader.vue'

export default {
  components: {
    Miniloader
  },
  props: {
    btnClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">

</style>