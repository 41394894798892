<template>
	<div class="wrapper" ref="wrapper">
		<Header />
		<main class="main">
			<router-view />
		</main>
		<transition name="fade">
			<Modal v-if="getError" @close="closePopup">
				<template slot="modal-content">
					<div class="popup__title">
						{{ getError }}
					</div>
				</template>
			</Modal>
		</transition>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Modal from '@/components/UI/Modal'

	export default {
		components: {
			Header,
			Modal,
		},
		data() {
			return {
				showPopup: false,
			}
		},
		computed: {
			getError() {
				return this.$store.getters.getErrorText
			},
		},
		methods: {
			closePopup() {
				this.$store.dispatch('updateError', '')
				this.showPopup = false
			},
		}
	}
</script>

<style>

</style>