function setTokens(tokenName, token){
	localStorage.setItem(tokenName, token);
}

function cleanTokens(tokenName){
	localStorage.removeItem(tokenName);
}

function getTokens(tokenName){
	return localStorage.getItem(tokenName);
}

export { setTokens, getTokens, cleanTokens }
