<template>
	<router-link :to="{name: 'UserSingle', params: {id: notice.data.user}}" class="notice-card" :class="notice.seen ? 'notice-card--seen' : ''" @click.native="updateUser(notice.data.user)">
		<span class="notice-card__title">
			<span>
				{{notice.title}}
			</span>
			<img src="@/assets/images/icons/arrow-right.svg" alt="arrow-right">
		</span>
		<span class="notice-card__text">
			{{ notice.body }}
		</span>
	</router-link>
</template>

<script>
	export default {
		props: {
			notice: {
				type: Object,
				require: true
			}
		},
		methods: {
			updateUser(userId) {
				if(this.$route.name !== 'UserSingle') return
				this.$store.dispatch('uploadUser', userId)
				this.$store.dispatch('uploadDevices', userId)
			}
		},
	}
</script>

<style lang="scss">
	.notice-card {
		flex: 1 0 calc(50% - 1.2rem);
		padding: 1.6rem;
		background-color: #F7F7FA;
		border-radius: 3rem;
		text-decoration: none;

		&--seen .notice-card__title span {
			color: #3C4853;
			background-color: #DBDCDE;
		}

		&__title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1.6rem;

			span {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				padding: 0.6rem 1.2rem;
				font-size: 1.2rem;
				line-height: 1.4;
				color: #008069;
				border-radius: 10rem;
				background-color: #D6F3EE;
			}
		}

		&__text {
			font-size: 1.3rem;
			line-height: 1.4;
			color: #8A8C96;
		}
	}

	.notice-clear {
		z-index: 2;
		position: sticky;
		bottom: -3rem;
		left: 0;
		width: 100%;
		max-width: 37.2rem;
		margin: 3rem auto 0;
		padding: 2.6rem;
		border-radius: 3rem;
		background-color: #ffffff;
		box-shadow: 0px -0.2rem 2rem rgba(113, 112, 128, 0.1);
	}
</style>