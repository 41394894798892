<template>
    <div class="table__tr table__device">
        <div class="table__td">
            <div class="text">
                {{device.vendor}}
            </div>
        </div>
        <div class="table__td">
            <div class="text">
                {{device.platform}}
            </div>
            <div class="text text--sm text--grey">
                Платформа
            </div>
        </div>
        <div class="table__td">
            <div class="text" :title="device.version">
                {{sliceStr}}
            </div>
            <div class="text text--sm text--grey">
                Версия системы
            </div>
        </div>
        <div class="table__td">
            <div class="text">
                {{device.ip}}
            </div>
            <div class="text text--sm text--grey">
                IP - адрес
            </div>
        </div>
        <div class="table__td">
            <div class="text">
                {{device.country}}, {{device.city}}
            </div>
            <div class="text text--sm text--grey">
                Геопозиция
            </div>
        </div>
        <div class="table__td">
            <div class="table__controls" v-if="device.isFirst">
                <div :class="'info-block'" v-if="device.isLoggedOut">
                    Сессия завершена
                </div>
                <template v-else>
                    <div :class="'info-block info-block--active'">
                        Текущая сессия активна
                    </div> 
                    <Button :class="'btn--white'" @click="changeDevice('logout', device.id)" >
                        Завершить сессию на устройстве
                    </Button>
                </template>
            </div>
            <template v-else>
                <button class="text text--red text--btn" v-if="device.blockedAt" @click="changeDevice('allow', device.id)">
                    Снять ограничение
                </button>
                <div class="table__controls" v-else>
                    <template v-if="!device.allowedAt">
                        <Button :class="'btn--sm'" @click="changeDevice('allow', device.id)" >
                            Разрешить вход
                        </Button>
                        <Button :class="'btn--red btn--sm'" @click="changeDevice('block', device.id)">
                            Запретить вход
                        </Button>
                    </template>
                    <template v-else>
                        <div :class="'info-block'" v-if="device.isLoggedOut">
                            Сессия завершена
                        </div>
                        <template v-else>
                            <div :class="'info-block info-block--active'">
                                Текущая сессия активна
                            </div>
                            <Button :class="'btn--white'" @click="changeDevice('logout', device.id)">
                                Завершить сессию на устройстве
                            </Button>
                        </template>
                    </template>
                </div>
            </template>
            <button class="slide-down" :class="{'opened': isOpened}" @click="toggleSlide">
                <img src="@/assets/images/icons/arrow-down.svg" alt="arrow-down">
            </button>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/UI/Button'

    export default {
        props: {
            device: {
                type: Object,
                require: true
            },
        },
        components: {
            Button,
        },
        data() {
            return {
                isSend: false,
                isOpened: false
            }
        },
        computed: {
            sliceStr() {
                return this.device.version.length > 20 ? this.device.version.slice(0, 20) + '...' : this.device.version.length
            }
        },
        methods: {
            toggleSlide() {
                this.isOpened = !this.isOpened
                this.$emit('toggleSlide')
            },
            async changeDevice(action, deviceId) {
                this.isSend = true
                await this.$store.dispatch('changeDevice', {
                    action,
                    deviceId,
                    userId: this.$route.params.id
                })
                this.isSend = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>