const API = {};

API.toShowSorting = function (wrapper, eventElemId) {
  let sortingElem = wrapper.querySelector(`#${eventElemId}`)
  sortingElem.classList.add('opened')
  wrapper.classList.add('tooltip-is-open')
}

API.toCloseSorting = function (wrapper) {
  let sortingElem = wrapper.querySelector(`.opened`)
  if (sortingElem) sortingElem.classList.remove('opened')
  wrapper.classList.remove('tooltip-is-open')
  return
}

API.incommingMsg = function () {
  try {
    const context = new AudioContext();
    window
      .fetch('./sound.wav')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        const source = context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(context.destination);
        source.start();
      });
  } catch (error) {
    console.log(error);
  }
}

export default API;