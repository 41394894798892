<template>
  <div class="table__th">
    <span class="table__th-icon" v-if="tHeader.isSorting">
      <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
        :data-id="'tooltip-id-' + tHeader.sorting.sortingTabs[0].sortingName">
    </span>
    <span class="table__th-icon" v-if="tHeader.isFilter">
      <img src="@/assets/images/icons/filter.svg" alt="filter" class="js-tooltip-icon"
        :data-id="'tooltip-id-' + tHeader.filters.filtersName">
    </span>
    <div v-html="tHeader.title"></div>
    <Sorting :sorting="tHeader.sorting" v-if="tHeader.isSorting"  @addOrdering="addOrdering"/>
    <Filtration :filters="tHeader.filters" v-if="tHeader.isFilter" @addFiltration="addFiltration"/>
  </div>
</template>


<script>
  import Sorting from '@/components/UI/Sorting'
  import Filtration from '@/components/UI/Filtration'

  export default {
    components: {
      Sorting,
      Filtration
    },
    props: {
      tHeader: {
        type: Object,
        require: true
      },
    },
    data() {
      return {
        currentOrdering: {}
      }
    },
    methods: {
      addOrdering(data) {
        let queryOption = this.$store.state.users.queryObject
        queryOption.ordering[data.queryGroup] = data.query
        queryOption.offset = null
        this.$store.dispatch('uploadUsers', queryOption)
      },
      addFiltration(data) {
        console.log('todo');
        // let queryOption = this.$store.state.users.queryObject
        // queryOption.filters[data.queryGroup] = data.query
        // queryOption.offset = null
        // this.$store.dispatch('uploadUsers', queryOption)
      },
    }
  }
</script>

<style lang="scss" scoped>
.table__th {
    max-width: calc(100% / 3);
}
</style>
