<template>
    <header id="header" class="header">
        <div class="header__logo">
            <img src="@/assets/images/icons/logo.svg" alt="">
        </div>
        <div class="header__controller" v-if="getProfile">
            <button class="btn btn--grey btn--add" :class="{ 'new-notice': getNotReadNotification}" @click="showNotice">
                <img src="@/assets/images/icons/bell.svg" alt="bell">
            </button>
            <div class="header__user">
                <div class="header__user-email">
                    {{getProfile.email}}
                </div>
                <div class="header__user-name">
                    {{getProfile.name}}
                </div>
            </div>
            <button class="header__btn" @click="logout">
                Выйти
            </button>
        </div>
    </header>
</template>

<script>
    import Button from '@/components/UI/Button'

    export default {
        components: {
            Button
        },
        computed: {
            getProfile() {
                return this.$store.getters.getProfile
            },
            getNotReadNotification() {
                return this.$store.getters.getNotReadNotification
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout')
            },
            showNotice() {
                this.$store.dispatch('uploadNotification')
                this.$emit('showNotice', true)
            }
        }
    }
</script>

<style lang="scss">

</style>