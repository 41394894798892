<template>
    <div class="table__tr">
        <div class="table__td">

        </div>
        <div class="table__td">
            <template v-if="session.createdAt">
                <div class="text">
                    {{ Math.floor(new Date(+session.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY") }}
                </div>
                <div class="text text--sm text--grey">
                    {{ Math.floor(new Date(+session.createdAt).getTime()/1000.0) | moment("HH:mm") }}
                </div>
            </template>

        </div>
        <div class="table__td">
            <template v-if="session.closedAt">
                <div class="text">
                    {{ Math.floor(new Date(+session.closedAt).getTime()/1000.0) | moment("DD.MM.YYYY") }}
                </div>
                <div class="text text--sm text--grey">
                    {{ Math.floor(new Date(+session.closedAt).getTime()/1000.0) | moment("HH:mm") }}
                </div>
            </template>
        </div>
        <div class="table__td">
            <div class="text" v-if="session.duration">
               {{ getTimeDuration }}
            </div> 
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            session: {
                type: Object,
                require: true
            },
        },
        computed: {
            getTimeDuration() {
                let duration = this.session.duration
                if (duration) {
                    let days = Math.floor(duration / (24 * 60 * 60))
                    let daysDuration = days * 24 * 60 * 60

                    let hours = Math.floor((duration - daysDuration) / (60 * 60));
                    let hoursDuration = hours * 60 * 60
                    
                    let minutes = Math.floor((duration - daysDuration - hoursDuration) / 60);
                    let seconds = Math.floor(duration - daysDuration - hoursDuration - minutes * 60);

                    duration = `${days} д ${this.addZero(hours)} ч ${this.addZero(minutes)} м ${this.addZero(seconds)} сек`
                }
                return duration
            },
        },
        methods: {
            addZero(num) {
                if (num == 0) return num
                return num < 10 ? `0${num}` : num
            }
        }
    }
</script>

<style lang="scss" scoped>
    .table__td {
        max-width: calc(100% / 4);
    }
</style>