<template>
  <div class="container">
    <div class="form-block">
      <ChangePasswordForm />
    </div>
  </div>
</template>


<script>
  import ChangePasswordForm from '@/components/Forms/ChangePassword'

  export default {
    components: {
      ChangePasswordForm
    },

  };
</script>

<style>

</style>