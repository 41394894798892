<template>
    <form class="search" :class="{'search--filter': withFilter}" @submit.prevent="onSearch">
        <span class="search__loupe">
            <img src="@/assets/images/icons/loupe.svg" alt="loupe">
        </span>
        <input type="text" class="search__input" name="search" placeholder="Что вы ищете?" v-model="searchText">
        <button class="search__btn" type="button" 
            v-show="searchText.length" 
            @click="clearSearch">
            Отмена
        </button>
    </form>
</template>

<script>
    export default {
        props: {
            withFilter: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                searchText: ''
            }
        },

        methods: {
            onSearch() {
                this.$emit('search', this.searchText)
            },
            clearSearch() {
                this.searchText = ''
                this.onSearch()
            }
        }
    }
</script>

<style lang="scss">

</style>