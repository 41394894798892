import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
navigator.serviceWorker.register("/firebase-messaging-sw.js", {scope: "/firebase-cloud-messaging-push-scope"}).then((reg) => {});

const firebaseConfig = {
    apiKey: "AIzaSyCV6hAOULc9_VOOM6Y612kExYmFdibFTUg",
    authDomain: "b2b-beton.firebaseapp.com",
    projectId: "b2b-beton",
    storageBucket: "b2b-beton.appspot.com",
    messagingSenderId: "130959679938",
    appId: "1:130959679938:web:38f1cc0abc6840c79e765b",
    measurementId: "G-17NNTVLJQ7"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };