const DeviceList = [{
    title: 'Устройства',
    isSorting: false,
    isFilter: false,
    sorting: {},
    filters: {}
  },
  {
    title: 'Начало сессии',
    isSorting: false,
    isFilter: false,
    sorting: {},
    filters: {}
  },
  {
    title: 'Окончание сессии',
    isSorting: false,
    isFilter: false,
    sorting: {},
    filters: {}
  },
  {
    title: 'Длительность сессии',
    isSorting: false,
    isFilter: false,
    sorting: {},
    filters: {}
  },

]

export default DeviceList