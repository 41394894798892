<template>
  <div class="table user-list">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx" />
    </div>
    <div class="table__body" v-if="users.length">
      <TableBodyRow v-for="(user, idx) in users" :key="idx" :user="user" />
    </div>
    <EmptyBlock v-else />
  </div>
</template>


<script>
  import tableHeaders from '@/data/UserList'
  import TableHeaderCell from './TableHeaderCell'
  import TableBodyRow from './TableBodyRow'
  import EmptyBlock from '@/components/Empty'

  export default {
    props: {
      users: {
        type: Array,
        required: true
      }
    },
    components: {
      TableHeaderCell,
      TableBodyRow,
      EmptyBlock,
    },
    data() {
      return {
        tableHeaders: tableHeaders
      }
    },
  }
</script>

<style lang="scss">

</style>