<template>
    <div class="title">
        <router-link :to="backLinkUrl" class="title__link" v-if="backLink">
            <img src="@/assets/images/icons/arrow-left.svg" alt="arrow-left">
        </router-link>
        <button class="title__link" v-if="backBtn" @click="$router.back()">
            <img src="@/assets/images/icons/arrow-left.svg" alt="arrow-left">
        </button>
        <h1 class="title__text" v-html="title"></h1>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            backLink: {
                type: Boolean,
                default: false
            },
            backLinkUrl: {
                type: Object,
                require: false
            },
            backBtn: {
                type: Boolean,
                default: false
            },
        },

    }
</script>

<style lang="scss">

</style>