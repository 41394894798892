<template>
  <form class="form form--login" @submit.prevent="onSubmit">
    <div class="form__inner">
      <div class="form__title">
        <h2>
          Авторизация
        </h2>
        <p>
          Для входа в учетную запись введите свои авторизационные данные
        </p>
      </div>
      <div class="form__fields">
        <div class="form__item" :class="{ 'form__item--error': $v.user.email.$error}">
          <input class="form__input" id="email" v-model.lazy="user.email" :class="{'form__input--empty': !user.email}"
            @change="$v.user.email.$touch()" placeholder="Логин (email)">
          <label for="email" class="form__label"><span>Логин</span></label>

          <p class="form__item-error" v-if="!$v.user.email.required">
            Обязательное поле
          </p>
          <p class="form__item-error" v-if="!$v.user.email.email">
            Введите email
          </p>
        </div>

        <div class="form__item" :class="{ 'form__item--error': $v.user.password.$error }">
          <input class="form__input" id="password" v-model.lazy="user.password"
            :class="{'form__input--empty': !user.password}" type="password" @change="$v.user.password.$touch()"
            placeholder="Пароль">
          <label for="password" class="form__label"><span>Пароль</span></label>
          <p class="form__item-error" v-if="!$v.user.password.required">
            Обязательное поле
          </p>
           <p class="form__item-error" v-if="!$v.user.password.minLength || !$v.user.password.maxLength || !$v.user.password.alpha">
              Код должен быть длиной от {{$v.user.password.$params.minLength.min}} до {{$v.user.password.$params.maxLength.max}} символов 
              и состоять из латинских букв любого регистра или цифр
          </p>
        </div>
      </div>


      <div class="form__controls" :class="{'form-send': isSend}">
        <Button>
          Войти
        </Button>
      </div>
    </div>

  </form>
</template>

<script>
  import {
    required,
    email,
    minLength,
    maxLength,
  } from 'vuelidate/lib/validators'
  import Button from '@/components/UI/Button'

  export default {
    components: {
      Button
    },
    data() {
      return {
        isSend: null,
        user: {
          email: '',
          password: ''
        }
      }
    },
    validations: {
      user: {
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(14),
          alpha: val => /^[a-zA-Z0-9]*$/i.test(val),
        },
      }
    },
    methods: {
      async onSubmit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const user = {
            email: this.user.email,
            password: this.user.password,
          }

          this.isSend = true
          await this.$store.dispatch('login', user)
          this.isSend = false

          if (this.$store.state.errorText) {
            this.user.email = ''
            this.user.password = ''
            this.$v.$reset()
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  
</style>