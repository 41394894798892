<template>
  <div class="popup">
    <div class="popup__window" @click.stop="">
      <span class="popup__close" @click="$emit('close')">
        <img src="@/assets/images/icons/close.svg" alt="close">
      </span>
      <div class="popup__inner">
        <slot name="modal-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss">

</style>