<template>
  <div class="table device-list">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx" />
    </div>
    <div class="table__body" v-if="devices.length">
      <div v-for="(device, idx) in getDevices" :key="idx" class="table__group">
        <TableDevice :device="device" @toggleSlide="toggleSlide(idx)" />
        <transition name="slide">
          <div class="table__sessions" v-if="device.isOpened">
            <TableBodyRow v-for="session in device.sessions" :key="session.id" :session="session" />
          </div>
        </transition>
      </div>
    </div>
    <EmptyBlock v-else />
  </div>
</template>


<script>
  import tableHeaders from '@/data/DeviceList'
  import TableHeaderCell from './TableHeaderCell'
  import TableDevice from './TableDevice'
  import TableBodyRow from './TableBodyRow'
  import EmptyBlock from '@/components/Empty'

  export default {
    props: {
      devices: {
        type: Array,
        required: true
      }
    },
    components: {
      TableHeaderCell,
      TableBodyRow,
      EmptyBlock,
      TableDevice,
    },
    data() {
      return {
        tableHeaders: tableHeaders,
        showMore: false,
      }
    },
    computed: {
      getDevices() {
        this.devices.forEach(elem => elem.isOpened = false)
        return this.devices
      }
    },
    methods: {
      toggleSlide(idx) {
        this.devices[idx].isOpened = !this.devices[idx].isOpened
        this.$forceUpdate()
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>